<template>
  <div id="guardian-register">
    <TopNavigation arrowAction="/" helpAction="/help" :passwordPage="passwordPage" :clickAction="backToSignup" :hasLogo="true" />

    <div v-if="!successPage" class="guardian-login-form">
      <form
        autocomplete="off"
        class="guardian-login-form__content"
        @submit.prevent="userRegister"
      >
        <h1 style="font-size: 1.1rem; text-align: center; font-weight: 100">
          Reset password
        </h1>
        <p
          style="
            text-align: center;
            font-size: 0.8rem;
            margin: 0 0 30px 0;
            font-weight: 100;
            color: #979797;
          "
        >
          Enter your new password
        </p>

        <template>
          <input
            v-model="register.password"
            class="guardian-login-form__input"
            placeholder="New Password"
            type="password"
            spellcheck="false"
            @keyup="validatePasswords"
          />
          <input
            id="repeatPassword"
            v-model="register.repeat_password"
            class="guardian-login-form__input mt"
            placeholder="Confirm New Password"
            type="password"
            spellcheck="false"
            @keyup="validatePasswords"
          />

          <div v-if="error" id="error">Passwords do not match!</div>
          <div class="form-container__requirements">
            <div class="container__requirements__element">
              <p>At least 8 characters</p>
              <img
                v-if="validation.item_1"
                src="../assets/images/check.svg"
                alt="Complete"
              />
              <img v-else src="../assets/images/cross.svg" alt="Incomplete" />
            </div>
            <div class="container__requirements__element">
              <p>At least one uppercase letter</p>
              <img
                v-if="validation.item_2"
                src="../assets/images/check.svg"
                alt="Complete"
              />
              <img v-else src="../assets/images/cross.svg" alt="Incomplete" />
            </div>
            <div class="container__requirements__element">
              <p>At least one number and special character</p>
              <img
                v-if="validation.item_3"
                src="../assets/images/check.svg"
                alt="Complete"
              />
              <img v-else src="../assets/images/cross.svg" alt="Incomplete" />
            </div>
          </div>
        </template>

        <button type="submit" class="guardian-login-form__button">
          <span v-if="isBtnLoading"
            ><svg
              id="L9"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve"
              width="40"
              height="40"
            >
              <path
                fill="#fff"
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="1s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite"
                />
              </path></svg></span>
          <span v-else>Continue</span>
        </button>
      </form>
    </div>
    <LoginFooter />
  </div>
</template>

<script>
import axios from "axios";
import config from "../../guardian/config";
import status from "../../guardian/utils/statusCode";
import LoginFooter from "../../login/components/LoginFooter";
import TopNavigation from "./TopNavigation";
import { mapActions } from "vuex";

export default {
  components: {
    LoginFooter,
    TopNavigation,
  },
  data() {
    return {
      isBtnLoading: false,
      successPage: false,
      passwordPage: false,
      error: false,
      disabledButton: true,
      buttonBg: "background: #dddddd;border-color: #dddddd;",
      register: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        repeat_password: "",
      },
      validation: {
        item_1: false,
        item_2: false,
        item_3: false,
      },
    };
  },
  methods: {
    ...mapActions(["TOAST"]),
    goToPasswordPage() {
      this.passwordPage = true;
    },
    backToSignup() {
      this.passwordPage = false;
    },
    validateButton() {
      let regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

      if (
        this.register.password.length >= 8 &&
        this.register.password.search(/[A-Z]/) >= 0 &&
        regex.test(this.register.password) &&
        this.register.password === this.register.repeat_password
      ) {
        this.disabledButton = false;
        this.buttonBg = "background: #00cdf2;border-color: #00cdf2;";
      } else {
        this.disabledButton = true;
        this.buttonBg = "background: #dddddd;border-color: #dddddd;";
      }
    },
    validatePasswords() {
      let repeatPassword = document.getElementById("repeatPassword");
      let regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

      this.register.password.length < 8
        ? (this.validation.item_1 = false)
        : (this.validation.item_1 = true);

      this.register.password.search(/[A-Z]/) < 0
        ? (this.validation.item_2 = false)
        : (this.validation.item_2 = true);

      regex.test(this.register.password)
        ? (this.validation.item_3 = true)
        : (this.validation.item_3 = false);

      if (repeatPassword === document.activeElement) {
        if (this.register.password !== this.register.repeat_password) {
          this.error = true;
        } else {
          this.error = false;
        }
      }

      if (
        this.register.password.length >= 8 &&
        this.register.password.search(/[A-Z]/) >= 0 &&
        regex.test(this.register.password) &&
        this.register.password === this.register.repeat_password
      ) {
        this.disabledButton = false;
        this.buttonBg = "background: #00cdf2;border-color: #00cdf2;";
      } else {
        this.disabledButton = true;
        this.buttonBg = "background: #dddddd;border-color: #dddddd;";
      }
    },
    userRegister() {
      if (
        (this.register.first_name &&
          this.register.last_name &&
          this.register.email,
        this.register.password,
        this.register.repeat_password)
      ) {
        const { first_name, last_name, email, password, repeat_password } =
          this.register;
        this.isBtnLoading = true;

        axios
          .post(config.API_HOST + "/invitationRequest/add", {
            email,
            first_name,
            last_name,
            password,
            repeat_password,
            sourceId: 1,
          })
          .then((response) => {
            const { statusCode } = response.data;
            this.TOAST(response.data.message);
            if (status.success(statusCode)) {
              this.successPage = true;
              this.isBtnLoading = false;
            } else if (status.invalid(statusCode)) {
              this.TOAST(response.data.message);
              this.isBtnLoading = false;
            } else {
              this.isBtnLoading = false;
              this.TOAST(response.data.message);
            }
          })
          .catch((error) => {
            throw error;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-top-nav {
  background: white;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  z-index: 100000;
  padding: 15px;
}

#guardian-register {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 15px;

  &__logo {
    height: 75px;
  }
}

.appSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  &__title {
    text-align: center;
    color: #7a6159;

    h1 {
      font-weight: bold;
      font-size: 18px;
    }

    p {
      margin-top: 10px;
      font-size: 13px;
    }

    img {
      width: 120px;
      margin-bottom: 20px;
    }
  }
}

.guardian-login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-bottom: auto;

  &__content {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background: white;
    border: 1px solid #dedede;
    padding: 25px;
    width: 85%;
  }

  &__input {
    background-color: #ebebeb;
    width: 100%;
    height: 48px;
    min-height: 48px;
    padding: 15px;
    border: 1px solid #d9d9d9;
    border-radius: 100px;
    outline: none;
    font-size: 14px;
    color: #444 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__title {
    margin-bottom: 40px;
    text-align: center;
    color: #7a6159;
    width: 85%;

    h1 {
      font-size: 20px;
    }

    p {
      margin-top: 5px;
      font-size: 13px;
    }
  }

  &__button {
    width: 200px;
    min-width: 200px;
    margin: 0 auto;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid var(--main);
    background: var(--main);
    color: white;
    outline: none;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 100ms ease-in-out;
    text-decoration: none;

    &:active {
      transform: scale(0.99);
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:focus {
      background-color: var(--main);
    }
  }

  &__forgot {
    margin: 10px 0 20px 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 85%;
    color: rgb(180, 180, 180);
    font-size: 14px;
  }

  &__register {
    margin-top: 25px;
    text-align: center;
    width: 85%;
    color: #777;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 5px;
    }
  }
}

::placeholder {
  color: #bebebe;
}

.mt {
  margin-top: 10px;
}

.form-container__requirements {
  margin: 30px 0 50px 0;
}

.container__requirements__element {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 0.8rem;
  word-wrap: break-word;
  color: #979797;
  margin-bottom: 5px;
}

.container__requirements__element img {
  margin-left: 20px;
}

#error {
  margin-top: 20px;
  color: #ff4f79;
  text-align: center;
  font-size: 0.8rem;
}
</style>
